import Moenterrey1 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (1).webp'
import Moenterrey2 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (2).webp'
import Moenterrey3 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (3).webp'
import Moenterrey4 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (4).webp'
import Moenterrey5 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (5).webp'
import Moenterrey6 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (6).webp'
import Moenterrey7 from '../../../../src/Media/Cibubur/Monterrey/Monterrey (7).webp'


const Moenterreyimages =[
Moenterrey1,Moenterrey2,Moenterrey3,Moenterrey4,Moenterrey5,Moenterrey6,Moenterrey7,
]

export default Moenterreyimages;