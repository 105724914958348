import Avenue1 from '../../../Media/Cibubur/ruko/avenue (1).webp'
import Avenue2 from '../../../Media/Cibubur/ruko/avenue (2).webp'
import Avenue3 from '../../../Media/Cibubur/ruko/avenue (3).webp'
import Avenue4 from '../../../Media/Cibubur/ruko/avenue (4).webp'
import Avenue5 from '../../../Media/Cibubur/ruko/avenue (5).webp'
import Avenue6 from '../../../Media/Cibubur/ruko/avenue (6).webp'

const Avenueimages =[
Avenue1,Avenue2,Avenue3,Avenue4,Avenue5,Avenue6
]

export default Avenueimages;