import ViolaL1 from '../../../src/Media/Kawasan/Citragran (1).webp'
import ViolaL2 from '../../../src/Media/Kawasan/Citragran (2).webp'
import ViolaL3 from '../../../src/Media/Kawasan/Citragran (3).webp'
import ViolaL4 from '../../../src/Media/Kawasan/Citragran (4).webp'



const ViolaLimages =[
ViolaL1,ViolaL2,ViolaL3,ViolaL4,
]

export default ViolaLimages;