import RegiaL1 from '../../../src/Media/Kawasan/Citraland (1).webp'
import RegiaL2 from '../../../src/Media/Kawasan/Citraland (2).webp'
import RegiaL3 from '../../../src/Media/Kawasan/Citraland (3).webp'
import RegiaL4 from '../../../src/Media/Kawasan/Citraland (4).webp'
import RegiaL5 from '../../../src/Media/Kawasan/Citraland (5).webp'
import RegiaL6 from '../../../src/Media/Kawasan/Citraland (6).webp'
import RegiaL7 from '../../../src/Media/Kawasan/Citraland (7).webp'


const RegiaLimages =[
RegiaL1,RegiaL2,RegiaL3,RegiaL4,RegiaL5,RegiaL6,RegiaL7,
]

export default RegiaLimages;