import Brunnera1 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (1).webp'
import Brunnera2 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (3).webp'
import Brunnera3 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (4).webp'
import Brunnera4 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (5).webp'
import Brunnera5 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (6).webp'
import Brunnera6 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (7).webp'
import Brunnera7 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (8).webp'
import Brunnera8 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (9).webp'
import Brunnera9 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (10).webp'
import Brunnera10 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (11).webp'
import Brunnera11 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (12).webp'
import Brunnera12 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (13).webp'
import Brunnera13 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (14).webp'
import Brunnera14 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (15).webp'
import Brunnera15 from '../../../Media/Cibubur/Brunnera/Brunnera L10 (16).webp'


const Brunneraimages =[
Brunnera1,
Brunnera2,
Brunnera3,
Brunnera4,
Brunnera5,
Brunnera6,
Brunnera7,
Brunnera8,
Brunnera9,
Brunnera10,
Brunnera11,
Brunnera12,
Brunnera13,
Brunnera14,
Brunnera15,
]

export default Brunneraimages;