import Surrounding1 from '../../../src/Media/Surrounding Cibubur/Surrounding (1).webp'
import Surrounding2 from '../../../src/Media/Surrounding Cibubur/Surrounding (2).webp'
import Surrounding3 from '../../../src/Media/Surrounding Cibubur/Surrounding (3).webp'
import Surrounding4 from '../../../src/Media/Surrounding Cibubur/Surrounding (4).webp'
import Surrounding5 from '../../../src/Media/Surrounding Cibubur/Surrounding (5).webp'
import Surrounding6 from '../../../src/Media/Surrounding Cibubur/Surrounding (6).webp'
import Surrounding7 from '../../../src/Media/Surrounding Cibubur/Surrounding (7).webp'
import Surrounding8 from '../../../src/Media/Surrounding Cibubur/Surrounding (8).webp'
import Surrounding9 from '../../../src/Media/Surrounding Cibubur/Surrounding (9).webp'





const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8,Surrounding9
]

export default Surroundingimages;