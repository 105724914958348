import Montana1 from '../../../Media/Cibubur/Montana/Montana L8 (1).webp'
import Montana2 from '../../../Media/Cibubur/Montana/Montana L8 (2).webp'
import Montana3 from '../../../Media/Cibubur/Montana/Montana L8 (3).webp'
import Montana4 from '../../../Media/Cibubur/Montana/Montana L8 (4).webp'
import Montana5 from '../../../Media/Cibubur/Montana/Montana L8 (5).webp'
import Montana6 from '../../../Media/Cibubur/Montana/Montana L8 (6).webp'
import Montana7 from '../../../Media/Cibubur/Montana/Montana L8 (7).webp'
import Montana8 from '../../../Media/Cibubur/Montana/Montana L8 (8).webp'
import Montana9 from '../../../Media/Cibubur/Montana/Montana L8 (9).webp'
import Montana10 from '../../../Media/Cibubur/Montana/Montana L8 (10).webp'



const Montanaimages =[
Montana1,
Montana2,
Montana3,
Montana4,
Montana5,
Montana6,
Montana7,
Montana8,
Montana9,
Montana10,
]

export default Montanaimages;