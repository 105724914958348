import Calathea1 from '../../../../src/Media/Cibubur/Calathea/Calathea (1).webp'
import Calathea2 from '../../../../src/Media/Cibubur/Calathea/Calathea (2).webp'
import Calathea3 from '../../../../src/Media/Cibubur/Calathea/Calathea (3).webp'
import Calathea4 from '../../../../src/Media/Cibubur/Calathea/Calathea (4).webp'
import Calathea5 from '../../../../src/Media/Cibubur/Calathea/Calathea (5).webp'
import Calathea6 from '../../../../src/Media/Cibubur/Calathea/Calathea (6).webp'
import Calathea7 from '../../../../src/Media/Cibubur/Calathea/Calathea (7).webp'
import Calathea8 from '../../../../src/Media/Cibubur/Calathea/Calathea (8).webp'
import Calathea9 from '../../../../src/Media/Cibubur/Calathea/Calathea (9).webp'
import Calathea10 from '../../../../src/Media/Cibubur/Calathea/Calathea (10).webp'

const Calatheaimages =[
Calathea1,Calathea2,Calathea3,Calathea4,Calathea5,Calathea6,Calathea7,Calathea8,Calathea9,Calathea10,
]

export default Calatheaimages;