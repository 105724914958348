import JasmiaL1 from '../../../src/Media/Kawasan/CBD (1).webp'
import JasmiaL2 from '../../../src/Media/Kawasan/CBD (2).webp'
import JasmiaL3 from '../../../src/Media/Kawasan/CBD (3).webp'
import JasmiaL4 from '../../../src/Media/Kawasan/CBD (4).webp'
import JasmiaL5 from '../../../src/Media/Kawasan/CBD (5).webp'
import JasmiaL6 from '../../../src/Media/Kawasan/CBD (6).webp'




const JasmiaLimages =[
JasmiaL1,JasmiaL2,JasmiaL3,JasmiaL4,JasmiaL5,JasmiaL6
]

export default JasmiaLimages;