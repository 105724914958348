import Marquette1 from '../../../Media/Cibubur/Marquette/Marquette (1).webp'
import Marquette2 from '../../../Media/Cibubur/Marquette/Marquette (2).webp'
import Marquette3 from '../../../Media/Cibubur/Marquette/Marquette (3).webp'
import Marquette4 from '../../../Media/Cibubur/Marquette/Marquette (4).webp'
import Marquette5 from '../../../Media/Cibubur/Marquette/Marquette (5).webp'


const Marquetteimages =[
Marquette1,Marquette2,Marquette3,Marquette4,Marquette5,
]

export default Marquetteimages;